export function CompleteButton(props: any) {
  return (
    <button
      className='complete-button-new'
      onClick={() => {
        props.answer();
      }}>
      Listo
    </button>
  );
}
