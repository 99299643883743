import { useParams } from "react-router";
import Layout from "../../components/layout";
import { useEffect, useState } from "react";
import {
  getTakenCourseLessons,
  nextQuestion,
  resetTakenCourseLessonService,
} from "../../services/studentCourses";
import { API_URL, BASE_URL } from "../../services/urlManager";
import { useSearchParams } from "react-router-dom";
import LectureStartHeader from "../../components/lesson-header/lection-start-header";
import "video-react/dist/video-react.css";
import { BigPlayButton, Player } from "video-react";

export default function LectureStart(props: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const lessonId = searchParams.get("lesson") ?? "";
  const stepId = searchParams.get("lessonStep") ?? "0";
  const questionId = searchParams.get("question") ?? "0";

  let { course } = useParams();
  const [info, setInfo] = useState<any>(null);
  const [nextStep, setNextStep] = useState<any>(null);
  const [currentQuestion, setCurrentQuestion] = useState<any>(null);

  useEffect(() => {
    getTakenCourseLessons(props?.aut?.token, String(course), lessonId)
      .then((res) => {
        setInfo(res);
        if (res.step !== null) {
          setNextStep(res.step);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // next question
  useEffect(() => {
    if (nextStep !== null && typeof nextStep !== "undefined") {
      getNextQuestion();
    }
  }, [nextStep]);

  async function getNextQuestion() {
    const response: any = await nextQuestion(
      props.aut.token,
      String(course),
      nextStep
    );

    setCurrentQuestion(response);
  }

  const resetTakenCourseLesson = (e: any, lessonId: number | null) => {
    e.preventDefault();
    e.stopPropagation();
    const href = e.target.href;
    const reset: any = resetTakenCourseLessonService(
      props.aut.token,
      Number(course ?? "0"),
      lessonId,
      href
    );
    console.log(reset);
  };

  return (
    <Layout
      aut={props.aut}
      _classes={"course-layout"}
      imageUrl={
        info !== null && info?.course_data.media_file !== null
          ? info?.course_data?.media_file
          : ""
      }>
      <div className='wrap conatiner-fluid'>
        {info !== null ? (
          <LectureStartHeader
            isLectureStart
            courseInfo={info !== null ? info : null}
            step={nextStep !== null ? nextStep : null}
            courseImage={
              info?.course_data?.lessons?.[0]?.media_file !== null
                ? info?.course_data?.lessons?.[0]?.media_file
                : ""
            }
          />
        ) : null}
        <div className='start-lecture-card'>
          <div className='image-question start-lesson-video aspect-video'>
            <Player
              playsInline
              src={
                !!info?.course_data?.lessons?.[0]?.video_media_file
                  ? API_URL +
                    "" +
                    (info?.course_data?.lessons?.[0]?.video_media_file ?? "")
                  : ""
              }>
              <BigPlayButton position='center' />
            </Player>
          </div>
        </div>
        <div className='start-lecture-button-container'>
          <a
            title='Ir a evaluación'
            className='complete-button-new'
            onClick={(e) => resetTakenCourseLesson(e, Number(lessonId))}
            href={
              BASE_URL +
              "/course/" +
              course +
              "/lecture" +
              "?lesson=" +
              (info?.course_data?.lessons?.[0]?.id ?? "") +
              "&lessonStep=" +
              (stepId ?? "0") +
              "&question=" +
              (questionId ?? "0") +
              "&totalQuestions=" +
              (info?.course_data?.lessons?.[0]?.total_questions ?? "0")
            }>
            Ir a evaluación
          </a>
        </div>
      </div>
    </Layout>
  );
}
