//import './lection-header.css';

interface Props {
  current?: any;
  courseInfo: any;
  question: number;
  lesson: number;
  totalQuestionNumber: any;
}

const QuestionHeader = (props: Props) => {
  return (
    <div className='question-endHeader'>
      <div className='title'>
        <div className='h'>{props?.current?.kind_question_name}</div>
        <div className='numerator'>
          Pregunta {props?.question + 1 + "/" + props.totalQuestionNumber}
        </div>
      </div>
      <div className='separator' />
      <div
        className='question-sentence'
        dangerouslySetInnerHTML={{ __html: props?.current?.body }}
      />
    </div>
  );
};

export default QuestionHeader;
