export function Home() {
  return (
    <div className='Home'>
      <div className='wrap container-fluid'>
        <div className='row middle-xs'>
          <div className='col-xs-12 col-md-6'>
            <div className='Home-textContainer'>
              <div className='Home-text'>
                <span>¡Bienvenid@</span>
                <span>a la Plataforma de</span>
                <span>E-learning de ANS!</span>
              </div>
              <div className='Home-description'>
                <p
                  style={{
                    marginTop: "2rem",
                    fontSize: "1.2rem",
                    marginBottom: "1rem",
                  }}>
                  Nuestras lecciones te guiarán desde el primer contacto con el
                  Portal ANS hasta la emisión y renovación de una póliza y mucho
                  más.
                </p>
                <span></span>
                <p
                  style={{
                    fontSize: "1.2rem",
                    marginBottom: "1rem",
                  }}>
                  Estamos aquí para proporcionarte la información que necesitas
                  y garantizar que tengas la mejor experiencia con ANS.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
