import {
  GET_ALL_TAKEN_COURSES,
  GET_TAKEN_COURSES,
  LAST_COURSE,
  LIKE_DISLIKE,
  NEXT_QUESTION,
  RESOLVE_QUESTION,
  SUSCRIBE,
  RESET_TAKEN_COURSE,
  RESET_TAKEN_COURSE_LESSON,
} from "./urlManager";

export const getAllTakenCourses = (token: string) => {
  const response = fetch(GET_ALL_TAKEN_COURSES, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    mode: "cors",
  })
    .then((response) => response.json())
    .then(function (result) {
      if (result.errors.length > 0) {
        return false;
      }
      return result.body;
    })
    .catch(function (e) {
      console.log("ERROR", e);
    });

  return response;
};

export const getTakenCourse = (token: string, courseId: string) => {
  const response = fetch(GET_TAKEN_COURSES + courseId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    mode: "cors",
  })
    .then((response) => response.json())
    .then(function (result) {
      if (result.errors.length > 0) {
        return false;
      }
      return result.body.takenCourse;
    })
    .catch(function (e) {
      console.log("ERROR", e);
    });

  return response;
};
export const getTakenCourseLessons = (
  token: string,
  courseId: string,
  lessonId: string
) => {
  const response = fetch(
    GET_TAKEN_COURSES + courseId + "/lessons?lessonId=" + lessonId,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      mode: "cors",
    }
  )
    .then((response) => response.json())
    .then(function (result) {
      //console.log(result);
      if (result.errors.length > 0) {
        return false;
      }
      return result.body.takenCourseLessons;
    })
    .catch(function (e) {
      console.log("ERROR", e);
    });

  return response;
};

export const resetTakenCourse = (
  token: string,
  courseId: number,
  lessonId: number | null,
  href: string
) => {
  return fetch(RESET_TAKEN_COURSE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    mode: "cors",
    body: JSON.stringify({
      course: courseId,
      lesson: lessonId ? lessonId : null,
    }),
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.errors.length > 0) return false;
      else {
        window.location.href = href;
        return true;
      }
    })
    .catch((e) => {
      console.log("ERROR", e);
      return false;
    });
};

export const resetTakenCourseLessonService = (
  token: string,
  courseId: number,
  lessonId: number | null,
  href: string
) => {
  return fetch(RESET_TAKEN_COURSE_LESSON, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    mode: "cors",
    body: JSON.stringify({
      course: courseId,
      lesson: lessonId ?? null,
    }),
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.errors.length > 0) return false;
      else {
        window.location.href = href;
        return true;
      }
    })
    .catch((e) => {
      console.log("ERROR", e);
      return false;
    });
};

export const nextQuestion = (token: string, courseId: string, step: any) => {
  const response = fetch(NEXT_QUESTION + courseId, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    mode: "cors",
    body: JSON.stringify(step),
  })
    .then((response) => response.json())
    .then(function (result) {
      //console.log(result);
      if (result.errors.length > 0) {
        return false;
      }
      return result;
    })
    .catch(function (e) {
      console.log("ERROR", e);
    });

  return response;
};

export const resolveQuestion = (
  token: string,
  courseId: string,
  responseBody: any
) => {
  //console.log(JSON.stringify(responseBody))
  const response = fetch(RESOLVE_QUESTION + courseId, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    mode: "cors",
    body: JSON.stringify(responseBody),
  })
    .then((response) => response.json())
    .then(function (result) {
      //console.log(result);
      if (result.errors.length > 0) {
        return false;
      }
      return result;
    })
    .catch(function (e) {
      console.log("ERROR", e);
    });

  return response;
};

export const likeOrDislike = (
  token: string,
  courseId: string,
  responseBody: any
) => {
  // console.log(JSON.stringify(responseBody));
  const response = fetch(LIKE_DISLIKE + courseId, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    mode: "cors",
    body: JSON.stringify(responseBody),
  })
    .then((response) => response.json())
    .then(function (result) {
      console.log(result);
      if (result.errors.length > 0) {
        return false;
      }
      return result;
    })
    .catch(function (e) {
      console.log("ERROR", e);
    });

  return response;
};

export const lastCourseMade = (token: string) => {
  const response = fetch(LAST_COURSE, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    mode: "cors",
  })
    .then((response) => response.json())
    .then(function (result) {
      if (result.errors.length > 0) {
        return false;
      }
      return result.body.takenCourse;
    })
    .catch(function (e) {
      console.log("ERROR", e);
    });

  return response;
};

export const suscribeCourse = (token: string, responseBody: any) => {
  // console.log(JSON.stringify(responseBody));
  const response = fetch(SUSCRIBE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    mode: "cors",
    body: JSON.stringify(responseBody),
  })
    .then((response) => response.json())
    .then(function (result) {
      console.log(result);
      if (result.errors.length > 0) {
        return false;
      }
      return result;
    })
    .catch(function (e) {
      console.log("ERROR", e);
    });

  return response;
};
