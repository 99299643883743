export function RedoButton(props: any) {
  return (
    <button
      className='redo-button-new'
      onClick={() => {
        props.redo();
      }}>
      Reiniciar Pregunta
    </button>
  );
}
