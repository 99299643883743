import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <div className='not-found-wrap'>
      <h1 className='text-60'>404</h1>
      <p className='text-36 subheading mb-3'>¡No encontrado!</p>
      <p className='mb-5  text-muted text-18'>
        La página que está buscando no existe o se ha movido.
      </p>
      <Link to='/' className='btn btn-lg btn-primary btn-rounded'>
        Ir a la página de inicio
      </Link>
    </div>
  );
}
