import { Modal } from "@mui/material";
import "./modalBox.css";
import { BASE_URL } from "../../services/urlManager";

interface Props {
  openModal: boolean;
  onModalClose: (value: boolean) => void;
  lastCourse: any;
}

const ModalBox = (props: Props) => {
  return (
    <Modal
      open={props.openModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <div className='modal-container'>
        <div className='modal-title'>¿Te gustaría retomar tu progreso?</div>
        <div className='modal-description'>
          {props.lastCourse !== undefined
            ? "Detectamos que ya has iniciado el curso: " +
              props.lastCourse.title
            : "Detectamos que ya has iniciado el curso."}
        </div>
        <div className='modal-description'>
          {" "}
          ¿Le gustaría reanudar su progreso o ir al inicio?
        </div>
        <div className='modal-btnContainer'>
          <button
            className='modal-confirmBtn'
            onClick={() => {
              props.onModalClose(false);
            }}>
            Ir al inicio
          </button>
          <a
            className='modal-cancelBtn'
            href={
              props.lastCourse !== undefined
                ? BASE_URL +
                  "/course/" +
                  props.lastCourse.id +
                  "/lecture-start" +
                  "?lesson=" +
                  (props.lastCourse?.course_data?.lessons?.[
                    props.lastCourse?.step?.lesson
                  ]?.id ?? "") +
                  "&lessonStep=" +
                  (props.lastCourse?.step?.lesson ?? "0") +
                  "&question=" +
                  (props.lastCourse?.step?.question ?? "0") +
                  "&totalQuestions=" +
                  (props.lastCourse?.course_data?.lessons?.[
                    props.lastCourse?.step?.lesson
                  ]?.total_questions ?? "0")
                : BASE_URL + "/"
            }>
            Ir al curso
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ModalBox;
