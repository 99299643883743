import { useEffect, useState } from "react";
import IncompletePhrase from "./QuestionComponents/incompletePhrase";
import MediaFile from "./QuestionComponents/mediaFile";
import QuestionHeader from "./QuestionComponents/question-header";
import { RedoButton } from "../RedoButton";
import { CompleteButton } from "../CompleteButton";
import CurrentTries from "./CurrentTries";
//import './lection.css';

interface Props {
  sentences: any;
  btnDisabled: boolean;
  onRespond: (value: any) => void;
  courseInfo: any;
  step: any;
  current: any;
  currentLessonStep: number;
  currentQuestionStep: number;
  totalQuestions: number;
}

const CompleteSentenceExercise = (props: Props) => {
  const incompletePhrase: any = props.sentences;
  const [answerArray, setAnswerArray] = useState<any>();

  let phrases: any = new Array(incompletePhrase.length);

  for (let i: number = 0; i < phrases.length; i++) {
    let words = incompletePhrase[i].split(" ");
    let _words: any = [];
    for (let w of words) {
      if (w == "________________") w = "";
      _words.push(w.trim());
    }

    phrases[i] = {
      id: i,
      words: _words,
    };
  }
  //console.log(phrases);

  let focusedElement: any = "";

  function setActiveElement(value: any) {
    focusedElement = value;
  }

  function SetResponse() {
    let newAnswerArray = answerArray;
    let _newAnswerArray: any = [];

    for (let i: number = 0; i < newAnswerArray.length; i++) {
      _newAnswerArray[i] = newAnswerArray[i].words
        .map(function (el: any) {
          return el.trim();
        })
        .join(" ");
    }
    newAnswerArray = _newAnswerArray;

    return newAnswerArray;
  }

  function reset() {
    console.log("RESETTING");

    let phrases: any = new Array(incompletePhrase.length);

    for (let i: number = 0; i < phrases.length; i++) {
      let words = incompletePhrase[i].split(" ");
      let _words: any = [];
      for (let w of words) {
        if (w == "________________") w = "";
        _words.push(w.trim());
      }

      phrases[i] = {
        id: i,
        words: _words,
      };
    }

    var inputs = document.getElementsByTagName("input");
    for (let i: number = 1; i < inputs.length; i++) {
      inputs[i].value = "";
    }
    setAnswerArray(phrases);
  }

  useEffect(() => {
    reset();
  }, [props]);

  return (
    <div className='lection'>
      <div className='lection-header'>
        <QuestionHeader
          current={props?.current}
          courseInfo={props?.courseInfo}
          question={props?.currentQuestionStep}
          lesson={props?.currentLessonStep}
          totalQuestionNumber={props?.totalQuestions}
        />
        <MediaFile
          media_type={props?.current?.media_type}
          media_file={props?.current?.media_file}
        />
      </div>
      <div className='lection-body'>
        <div className='lection-columnContainer'>
          {incompletePhrase?.map((phrase: any, index: number) => (
            <div key={index} className='lection-sentence'>
              {
                <div
                  key={index}
                  className='custom-indexer'
                  style={{ marginBottom: "1rem" }}>
                  <div className='circle-number'>{index + 1}</div>
                  <IncompletePhrase
                    phrase={phrase}
                    index={index}
                    answersHolder={answerArray}
                    focusedElement={setActiveElement}
                  />
                </div>
              }
            </div>
          ))}
        </div>
      </div>
      <div className='separator separator-foo' />
      <div className='lection-footer'>
        <div className='flex items-center'>
          {(props?.current?.tries !== undefined ||
            props?.current?.tries !== null) && (
            <CurrentTries tries={props?.current?.tries} className='mr-4' />
          )}
          <CompleteButton
            answer={() => {
              props.onRespond(SetResponse);
            }}
          />
        </div>
        <RedoButton redo={reset} />
      </div>
    </div>
  );
};
export default CompleteSentenceExercise;
