//export const API_URL = 'http://localhost:4180'
export const API_URL = "https://capacitacion.ans.cl/api";

// export const API_URL = "http://localhost:3000/api";
export const BASE_URL = "";
export const API_BASE_URL = "/api";

export const LOGIN_URL = API_URL + "/login";
export const REGISTER_URL = API_URL + "/register";
export const ME_URL = API_URL + "/me";

export const GET_ALL_TAKEN_COURSES = API_URL + "/student/courses";
export const GET_TAKEN_COURSES = API_URL + "/student/course/";
export const NEXT_QUESTION = API_URL + "/student/course/next-question/";

export const RESOLVE_QUESTION = API_URL + "/student/course/resolve-question/";

export const LIKE_DISLIKE = API_URL + "/users/student/set-like-dislike/";

export const LAST_COURSE = API_URL + "/users/student/last-course";

export const SUSCRIBE = API_URL + "/users/student/subscribe-course";

export const RESET_TAKEN_COURSE = API_URL + "/users/student/reset-course";
export const RESET_TAKEN_COURSE_LESSON =
  API_URL + "/users/student/reset-course-lesson";

export const DEAFAUL_MAX_TRIES = 4;
