import { MenuItem, Select } from "@mui/material";
import Cookies from "js-cookie";
import React from "react";
import { BASE_URL } from "../services/urlManager";
//import './layout.css'

class Layout extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  Logout() {
    Cookies.remove("user");
    window.location.href = BASE_URL + "/";
  }

  render() {
    const myBackgroundImage = {
      backgroundImage: "url(" + this.props.imageUrl + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };

    return (
      <div className='wrapper'>
        <div
          className={"Layout" + " " + this.props._classes}
          style={this.props.imageUrl ? myBackgroundImage : {}}>
          <div className='header-parent'>
            <div className='wrap container-fluid container-fluid-padding'>
              <div className='Layout-header'>
                <a href={BASE_URL + "/"} className='Layout-header-logo'>
                  <img src={require("../Assets/logo_ANS.png")} />
                </a>
                <div className='Layout-header-avatarContainer'>
                  <div className='Layout-header-avatar'>
                    <img src={require("../Assets/avatar_Icon.png")} />
                  </div>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={1}
                    label='Username'
                    sx={{ color: "white" }}
                    onChange={() => {}}>
                    <MenuItem value={1}>{this.props.aut.name}</MenuItem>
                    <MenuItem
                      value={2}
                      onClick={() => {
                        this.Logout();
                      }}>
                      Logout
                    </MenuItem>
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className='Layout-body'>{this.props.children}</div>
          <div className='Layout-footer'>
            <div className='wrap container-fluid'>
              <div className='Layout-footer-body'>
                <div className='Layout-footer-textContainer'>
                  <div className='Layout-footer-title-text'>ANS Learning</div>
                  <div className='Layout-footer-body-text'>
                    La concepción 322, Providencia.
                  </div>
                  <div className='Layout-footer-body-text'>
                    Todos los Derechos Reservados.
                  </div>
                </div>
                <ul className='Layout-footer-iconContainer'>
                  <li>
                    <a
                      href='https://www.facebook.com/anschile/?locale=es_LA'
                      target='_blank'
                      rel='noreferrer'>
                      <img
                        className='Layout-footer-icon'
                        src={require("../Assets/icons/ico-facebook.png")}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.instagram.com/comunidadans/'
                      target='_blank'
                      rel='noreferrer'>
                      <img
                        className='Layout-footer-icon'
                        src={require("../Assets/icons/ico-instagram.png")}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://es.linkedin.com/company/ans-sa'
                      target='_blank'
                      rel='noreferrer'>
                      <img
                        className='Layout-footer-icon'
                        src={require("../Assets/icons/ico-linkedin.png")}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/*<img
                            className='Layout-footer-image'
                            src={require('../Assets/images/footer.png')}
        />*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
