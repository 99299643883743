import { Alert, Container, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { login } from "../../services/loginService";
//import './login.css';
import Cookies from "js-cookie";
import { userProfile } from "../../services/userService";
import { BASE_URL } from "../../services/urlManager";
const Login = () => {
  //const { handleLogin, token, hostname, isLoged } = useContext(AdminContext);
  const [enableLoginButton, setEnableLoginButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      user: "",
      password: "",
    },
    validationSchema: Yup.object({
      user: Yup.string()
        .email("El email no es válido")
        .max(255)
        .required("El email es requerido"),
      password: Yup.string().max(255).required("La contraseña es requerida"),
    }),
    onSubmit: (values: any) => {
      Submit(values);
    },
  });

  const Submit = async (values: any) => {
    const response = await login(values);
    if (typeof response == "string") {
      setErrorMessage(response);
      setIsError(true);
      formik.setFieldValue("password", "");
    } else {
      setIsError(false);
      const profile = await userProfile(response.token);
      if (profile) {
        const userInfo = { name: profile, ...response };
        Cookies.set("user", JSON.stringify(userInfo));
        window.location.reload();
      }
    }
  };

  return loading ? null : (
    <div>
      <header className='header-wrapper'>
        <div className='header-login-wrapper'>
          <a href={BASE_URL + "/"} className='header-login-wrapper'>
            <img src={require("../../Assets/logo_ANS.png")} />
          </a>
        </div>
      </header>
      <div className='wrapper login-wrapper'>
        <div className='Login'>
          <Container maxWidth='xs' className='LoginContainer'>
            <div className='LoginContainer-header'>
              <div className='LoginContainer-title'>
                Aprende a sacarle el máximo rendimiento a Mi Portal ANS
              </div>
              <div className='login-icon'>
                <img
                  className='icon'
                  src={require("../../Assets/avatar_Icon.png")}
                />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                error={Boolean(formik.touched.user && formik.errors.user)}
                fullWidth
                helperText={formik.touched.user && formik.errors.user}
                label='Email'
                margin='normal'
                name='user'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type='email'
                value={formik.values.user}
                variant='outlined'
                onClick={() => {
                  setEnableLoginButton(true);
                }}
              />
              <TextField
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label='Contraseña'
                margin='normal'
                name='password'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type='password'
                value={formik.values.password}
                variant='outlined'
                onClick={() => {
                  setEnableLoginButton(true);
                }}
              />
              <input
                className='LoginContainer-button'
                disabled={!enableLoginButton}
                type='submit'
                value='Iniciar Sesión'
              />
            </form>
            <div className='LoginContainer-footerText'>
              Si olvidaste tu contraseña, escribe un correo a{" "}
              <a
                className='to-mailto'
                href='mailto:soporte@anslearning.cl'
                title='Soporte ANS Learning'>
                soporte@anslearning.cl
              </a>
            </div>
          </Container>
          {isError ? (
            <Alert severity='error' className='error-alert'>
              {errorMessage}
            </Alert>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Login;
