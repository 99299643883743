import { useEffect, useState } from "react";
//import './lection.css';
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import QuestionDroppable from "./QuestionComponents/questionDroppable";
import AnswerDraggable from "./QuestionComponents/answerDraggable";
import QuestionHeader from "./QuestionComponents/question-header";

import MediaFile from "./QuestionComponents/mediaFile";
import { RedoButton } from "../RedoButton";
import { CompleteButton } from "../CompleteButton";
import CurrentTries from "./CurrentTries";

interface Props {
  questions: any;
  options: string[];
  onRespond: (value: any) => void;
  courseInfo: any;
  step: any;
  current: any;
  currentLessonStep: number;
  currentQuestionStep: number;
  totalQuestions: number;
}

const DragNDropCompleteSentence = (props: Props) => {
  const [phrases, setPhrases] = useState<any>(props.questions);
  //const [phrases, setPhrases] = useState<any>('________________ é secretária ________________');
  const [options, setOptions] = useState(props.options);
  //const [options, setOptions] = useState(['hola', 'soy', 'jorge']);

  const [answers, setAnswers] = useState<any>([]);

  let stringOptions: any = "";
  options.map((e: any, i: number) => {
    stringOptions += options[i] + "|";
  });

  let optionsArray: any = new Array(stringOptions.split("|").length);
  stringOptions.split("|").map((card: any, i: number) => {
    optionsArray[i] = {
      id: i,
      phrase: card,
    };
  });
  optionsArray.pop();

  let phraseArray: any = [];
  if (!Array.isArray(phrases)) {
    phraseArray = new Array(1);
  } else {
    phraseArray = new Array(phrases.length);
  }

  function setArrays() {
    const phrasesArray = props.questions;

    let multiArray: any[] = [];
    for (let i = 0; i < phrasesArray.length; i++) {
      let cards = phrasesArray[i].split(" ");
      let _cards: any = [];
      for (let c of cards) {
        if (c == "________________") c = "";
        _cards.push(c.trim());
      }
      let obj: any = {
        id: String(i),
        name: Array.isArray(phrasesArray) ? phrasesArray[i] : phrasesArray,
        cards: _cards,
      };
      multiArray.push(obj);
    }

    /*
        let multiArray: any[] = [];
        for (let i = 0; i < phraseArray.length; i++) {            
            let obj: any = {
                id: String(i),
                name: Array.isArray(phrasesArray) ? phrasesArray[i] : phrasesArray,
                cards: Array.isArray(phrasesArray) ? new Array(phrasesArray[i].split('________________').length) : new Array(phrasesArray.split('________________').length)
            }
            multiArray.push(obj);
        }
        */
    //console.log( multiArray );
    setAnswers(multiArray);
    console.log(answers);
  }

  const onDragEndComplete = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    //console.log("DRAG: ", result, destination?.droppableId.split('droppable'));

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let newOptionsArray = optionsArray;
    let answersArray = answers;

    //console.log( destination, source );

    if (
      source.droppableId == "answerDroppableHolder" &&
      destination.droppableId !== "answerDroppableHolder"
    ) {
      let dropIndex = destination.droppableId.split("droppable")[0];
      let current = newOptionsArray[source.index];

      let old =
        answersArray[dropIndex.split("-")[1]].cards[dropIndex.split("-")[0]];

      if (old !== "") {
        newOptionsArray[old.id] = old;
      }

      answersArray[dropIndex.split("-")[1]].cards[dropIndex.split("-")[0]] =
        current;
      newOptionsArray[source.index] = "";
    } else if (
      source.droppableId !== "answerDroppableHolder" &&
      destination.droppableId == "answerDroppableHolder"
    ) {
      let sourceIndex = source.droppableId.split("droppable")[0];
      let current =
        answersArray[sourceIndex.split("-")[1]].cards[
          sourceIndex.split("-")[0]
        ];

      newOptionsArray[current.id] = current;
      answersArray[sourceIndex.split("-")[1]].cards[sourceIndex.split("-")[0]] =
        "";
    } else if (
      source.droppableId !== "answerDroppableHolder" &&
      destination.droppableId !== "answerDroppableHolder"
    ) {
      let sourceIndex = source.droppableId.split("droppable")[0];
      let dropIndex = destination.droppableId.split("droppable")[0];

      let newItem =
        answersArray[sourceIndex.split("-")[1]].cards[
          sourceIndex.split("-")[0]
        ];
      let old =
        answersArray[dropIndex.split("-")[1]].cards[dropIndex.split("-")[0]];

      answersArray[sourceIndex.split("-")[1]].cards[sourceIndex.split("-")[0]] =
        old;
      answersArray[dropIndex.split("-")[1]].cards[dropIndex.split("-")[0]] =
        newItem;
    }

    /*        
        
        let index: any = draggableId.split('draggable-')[1];
        let dropIndex: any = destination.droppableId.split('droppable')[0].split('-')[0];
        let dragIndex: any = '';
        let current = {
            id: index,
            phrase: stringOptions.split('|')[index]
        }

        let old: any;
        let sourceIndex: number = answersArray?.findIndex(
            (x: any) => {                
                return x.name == source.droppableId.split('droppable')[1]
            }
        );
        
        let destinationIndex: number = answersArray?.findIndex(
            (x: any) => {
               
                return x.name == destination.droppableId.split('droppable')[1]
            }
        );

      
        if (source.droppableId !== "answerDroppableHolder") {
            console.log('FROM ABOVE');
            dragIndex = source.droppableId.split('droppable')[0];            
            old = answersArray[destinationIndex].cards[dropIndex];         
            answersArray[sourceIndex].cards[dragIndex] = '';
        } else {
            console.log('FROM BELOW');           
            if (answersArray[destinationIndex].cards[dropIndex] !== undefined)
                old = answersArray[destinationIndex].cards[dropIndex];            
            newOptionsArray[index] = '';
        }

        // Destination Logic
        if (destination.droppableId !== 'answerDroppableHolder') {
            console.log("REPLACE TO BELOW", old);
            if (old !== undefined && old !== '') {               
                newOptionsArray[old.id] = old;
            }
            console.log('ADD TO ABOVE');
           
            answersArray[destinationIndex].cards[dropIndex] = current;
        } else {
            console.log('ADD TO BELOW');           
            newOptionsArray[index] = current;
        }
        */
    //console.log(optionsArray, answersArray);
  };

  function Reset() {
    stringOptions = "";
    const optionArray: any = props.options;
    const prhasesArray: any = props.questions;

    optionArray.map((e: any, i: number) => {
      stringOptions += optionArray[i] + "|";
    });

    optionsArray = new Array(stringOptions.split("|").length);
    stringOptions.split("|").map((card: any, i: number) => {
      optionsArray[i] = {
        id: i,
        phrase: card,
      };
    });
    optionsArray.pop();

    phraseArray = [];
    if (!Array.isArray(prhasesArray)) {
      phraseArray = new Array(1);
    } else {
      phraseArray = new Array(prhasesArray.length);
    }

    setArrays();
  }

  function setResponse() {
    let currentAnswerArray: any = new Array(phrases.length);

    console.log(answers);

    for (let i: number = 0; i < answers.length; i++) {
      let _answer: any = [];

      for (let _a of answers[i].cards) {
        if (typeof _a == "string") _answer.push(_a.trim());
        else if ("phrase" in _a) _answer.push(_a.phrase.trim());
        else _answer.push("");
      }

      currentAnswerArray[i] = _answer.join(" ");
    }
    console.log(currentAnswerArray);

    return currentAnswerArray;
  }

  useEffect(() => {
    Reset();
  }, [props]);

  return (
    <div className='lection'>
      <div className='lection-header'>
        <QuestionHeader
          current={props?.current}
          courseInfo={props?.courseInfo}
          question={props?.currentQuestionStep}
          lesson={props?.currentLessonStep}
          totalQuestionNumber={props?.totalQuestions}
        />
        <MediaFile
          media_type={props?.current?.media_type}
          media_file={props?.current?.media_file}
        />
      </div>
      <div className='lection-body'>
        <div className='lection-container'>
          <DragDropContext onDragEnd={onDragEndComplete}>
            {Array.isArray(phrases) ? (
              phrases?.map((question: any, index: number) => (
                <div
                  key={index}
                  className='custom-indexer'
                  style={{ marginBottom: "1rem" }}>
                  <div className='circle-number'>{index + 1}</div>
                  <QuestionDroppable
                    key={"key-" + index}
                    sentence={question}
                    questionId={index}
                    answerCards={answers[index]}
                  />
                </div>
              ))
            ) : (
              <div
                key={0}
                className='custom-indexer'
                style={{ marginBottom: "1rem" }}>
                <div className='circle-number'>{1}</div>
                <QuestionDroppable
                  sentence={phrases}
                  questionId={0}
                  answerCards={answers[0]}
                />
              </div>
            )}
            <Droppable droppableId='answerDroppableHolder'>
              {(provided) => (
                <div
                  className='lection-optionsContainer'
                  ref={provided.innerRef}
                  {...provided.droppableProps}>
                  {optionsArray?.map((card: any, index: number) =>
                    card !== undefined && card !== "" ? (
                      <AnswerDraggable
                        key={"drag-key-" + card.id}
                        answerText={card.phrase}
                        answerId={card.id}
                        index={index}
                      />
                    ) : null
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <div className='separator separator-foo' />
      <div className='lection-footer'>
        <div className='flex items-center'>
          {(props?.current?.tries !== undefined ||
            props?.current?.tries !== null) && (
            <CurrentTries tries={props?.current?.tries} className='mr-4' />
          )}
          <CompleteButton
            answer={() => {
              props.onRespond(setResponse);
              //Reset();
            }}
          />
        </div>

        <RedoButton redo={Reset} />
      </div>
    </div>
  );
};

export default DragNDropCompleteSentence;
