import { useEffect } from "react";
import { API_BASE_URL, API_URL } from "../../services/urlManager";

interface Props {
  courseInfo: any;
  step: any;
  courseImage: any;
  isLectureStart?: boolean;
}

const LectureStartHeader = (props: Props) => {
  let perc =
    props?.courseInfo?.course_data?.lessons?.[props?.step?.lesson]
      ?.percent_complete;
  let total =
    props?.courseInfo?.course_data?.lessons?.[props?.step?.lesson]
      ?.total_questions;
  let questionArray: any = new Array(total);

  let current;
  if (perc > 0) current = (perc / 100) * total;
  else current = 0;

  for (let i: number = 0; i < questionArray.length; i++) {
    if (i >= props?.step?.question + 1) {
      questionArray[i] = false;
    } else {
      questionArray[i] = true;
    }
  }

  let stylePos = {};

  useEffect(() => {
    let width: any = document
      .getElementById("plan-progressContainer")
      ?.getBoundingClientRect();
    width = width?.width - 42;
    let divisor =
      props?.courseInfo?.course_data?.lessons?.[props?.step?.lesson]
        ?.total_questions - 1;
    divisor = divisor === 0 ? 1 : divisor;

    let unitMovement = width / divisor;
    let position = props?.courseInfo.step?.question;

    let plane: any = document.getElementById("target-img-plane");
    if (plane) {
      plane.style.left = position * unitMovement + "px";
    }
  });

  return (
    <div className='lecture-endHeader'>
      <div className='lecture-endHeader-container'>
        <div className='lecture-avatarHeader'>
          <div className='lecture-avatar'>
            <div className='avatarImage'>
              {props?.courseImage !== "" ? (
                <img src={API_URL + "" + (props?.courseImage ?? "")} />
              ) : (
                <img src={require("../../Assets/images/curso-small.jpg")} />
              )}
            </div>
            <div className='avatarTextContainer'>
              <div className='avatar-title'>
                {props?.courseInfo?.course_data?.title ?? "Sin Información"}
              </div>
              <div className='avatar-subtitle'>
                {props?.courseInfo.course_data?.level_class_name}
              </div>
            </div>
            <div className='lecture-questionTitle'>
              {props?.courseInfo?.course_data.lessons?.[0]?.title !==
              "undefined"
                ? props?.courseInfo?.course_data.lessons?.[0]?.title
                : "Lección " + (props?.step?.lesson + 1)}
            </div>
          </div>
        </div>
        {props.isLectureStart ? null : (
          <>
            <div className='separator'></div>
            <div className='lecture-questionTitle'>
              PREGUNTAS DE ESTA LECCIÓN
            </div>
            <div id='plan-progressContainer' className='plan-progressContainer'>
              {questionArray?.map((question: any, index: number) =>
                question ? (
                  <div className='line-plane' key={index}>
                    <img src={require("../../Assets/icons/ans-line2.png")} />
                  </div>
                ) : null
              )}
              <div id='target-img-plane' className='img-plane' style={stylePos}>
                <img src={require("../../Assets/icons/ans-plane.png")} />
              </div>
              {questionArray?.map((question: any, index: number) =>
                !question ? (
                  <div className='line-plane' key={index}>
                    <img
                      src={require("../../Assets/icons/ans-line-grey2.png")}
                    />
                  </div>
                ) : null
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LectureStartHeader;
