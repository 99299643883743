//import '../lection.css';

import { useState } from "react";

interface Props {
  phrase: any;
  index: number;
  answersHolder: any;
  focusedElement: any;
}

const IncompletePhrase = (props: Props) => {
  // console.log("answersHolder", props.answersHolder);
  //let phraseArray: any = props.phrase.split('________________');
  let phraseArray: any = props.phrase.split(" ");
  //console.log( props.phrase, phraseArray  )
  return (
    <div className='question-container' style={{ flexWrap: "wrap" }}>
      {phraseArray?.map((phrase: any, index: number) => (
        <div
          key={"text-key-" + index}
          className='lextion-sentence'
          style={{ marginRight: "0.5rem" }}>
          {phrase.trim() != "________________" ? (
            phrase.trim()
          ) : (
            <input
              id={props.phrase + "|" + props.index + "|" + index}
              //id={"unknow-phrase" + "|" + props.index + "|" + index}
              style={{ marginBottom: "0.5rem", marginRight: 0 }}
              className='lection-questionSlot'
              onChange={(event) => {
                props.answersHolder[props.index].words.splice(
                  index,
                  1,
                  event.target.value
                );
                props.focusedElement(document.activeElement);
                event.preventDefault();
              }}
              onClick={() => props.focusedElement(document.activeElement)}
            />
          )}
        </div>
        /*
                            index !== phraseArray.length - 1 ?
                                <>
                                    <div className="lection-sentence">{phrase}</div>
                                    <input id={props.phrase + "|" + props.index + "|" + index} style={{ marginBottom: "0.5rem" }} className="lection-questionSlot" onChange={(event) => {
                                        props.answersHolder[props.index].words.splice(index, 1, event.target.value);  // = event.target.value;
                                        props.focusedElement(document.activeElement);
                                        //console.log(props.answersHolder);
                                        event.preventDefault()
                                    }} onClick={() => props.focusedElement(document.activeElement)} />
                                </>
                                :
                                <div key={"text-key-" + index} className="lection-sentence">{phrase}</div>
                            */
      ))}
    </div>
  );
};

export default IncompletePhrase;
