import React from "react";
import Layout from "../../components/layout";
import {
  getTakenCourse,
  resetTakenCourse,
} from "../../services/studentCourses";
import LinearWithValueLabel from "./LinearWithValueLabel";
import { API_URL, BASE_URL } from "../../services/urlManager";

export class CoursePage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    let idCourse: string = String(
      new URLSearchParams(window.location.search).get("course")
    );

    this.state = {
      idCurso: idCourse,
      curso: null,
    };
    // si no hay datos, reedireccionar al 404
  }

  resetTakenCourse = (e: any, lessonId: number | null) => {
    e.preventDefault();
    e.stopPropagation();

    const href = e.target.href;
    const courseId = this.state.idCurso;

    const reset: any = resetTakenCourse(
      this.props.aut.token,
      courseId,
      lessonId,
      href
    );
    console.log(reset);
    return;
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  async componentDidMount() {
    const currentCurso = await getTakenCourse(
      this.props.aut.token,
      this.state.idCurso
    );

    this.setState({
      curso: currentCurso,
    });
  }

  render() {
    //let currentCourse: Course = this.state.curso;

    return (
      <Layout
        aut={this.props.aut}
        _classes='course-layout'
        imageUrl={
          this.state.curso !== null
            ? this.state.curso.course_data.media_file
            : ""
        }>
        {this.state.curso !== null ? (
          <div className='wrap container-fluid'>
            <div className='Course'>
              <div className='course-headContainer'>
                <div className='title-container'>
                  <div className='title'>
                    {this.state.curso.course_data.title}
                  </div>
                  <div className='level-header'>
                    {this.state.curso.course_data.level_class_name}
                  </div>
                </div>
                <div className='separator' />
                <div className='description-header'>
                  {this.state.curso.course_data.body}
                </div>
              </div>
              <div className='course-body'>
                <div className='title-body text-primary-100'>
                  LECCIONES DEL CURSO
                </div>
                <div className='lecture-list'>
                  {this.state.curso.course_data.lessons?.map(
                    (lesson: any, index: number) => (
                      <div
                        key={lesson.id}
                        className='lecture-item-container shadow-xl'>
                        <div className='lecture-item'>
                          <div className='lecture-item-icon w-35'>
                            <div className='lecture-icon'>
                              {lesson.media_file !== null ? (
                                <img src={API_URL + "" + lesson.media_file} />
                              ) : (
                                <img
                                  src={require("../../Assets/images/home2.png")}
                                />
                              )}
                            </div>
                            <div className='lecture-title'>
                              {lesson.name !== "undefined"
                                ? lesson.name.substring(0, 40) + "..."
                                : "Lección " + (lesson.id + 1)}
                            </div>
                          </div>
                          <div className='w-full w-30'>
                            <LinearWithValueLabel
                              progressValue={lesson.percent_complete}
                            />
                          </div>
                          <div className='reset-lecture-btn-container w-35'>
                            <a
                              className='reset-lecture-btn'
                              onClick={(e) =>
                                this.resetTakenCourse(e, lesson?.id)
                              }
                              href={
                                BASE_URL +
                                "/course/" +
                                this.state.idCurso +
                                "/lecture-start?lesson=" +
                                lesson.id +
                                "&lessonStep=" +
                                lesson?.coord +
                                "&question=0" +
                                "&totalQuestions=" +
                                lesson?.total_questions
                              }>
                              REINICIAR LECCIÓN
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  {this.state.curso.step == null ? (
                    <div className='button-area end'>
                      <a
                        className='curso-btn btn'
                        onClick={(e) => this.resetTakenCourse(e, null)}
                        href={
                          BASE_URL +
                          "/course/" +
                          this.state.idCurso +
                          "/lecture-start?lesson=" +
                          (this.state?.curso?.course_data?.lessons?.[0]?.id ??
                            "")
                        }>
                        REINICIAR CURSO
                      </a>
                    </div>
                  ) : (
                    <div className='button-area end'>
                      <a
                        className='curso-btn btn'
                        href={
                          BASE_URL +
                            "/course/" +
                            this.state?.idCurso +
                            "/lecture-start?lesson=" +
                            this.state?.curso?.course_data?.lessons?.[0]?.id ??
                          ""
                        }>
                        REALIZAR CURSO
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Layout>
    );
  }
}
