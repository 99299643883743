import React from "react";
import "./Assets/flexboxgrid.min.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from "js-cookie";
import Login from "./pages/login/login";
import HomeCourses from "./pages/home-courses/home-courses";
import { CoursePage } from "./pages/course/course";
import { Lecture } from "./pages/lecture/lecture";
import LectionEnd from "./pages/lecture-end/lecture-end";
import "./index.css";
import ErrorPage from "./pages/404";
import LectureStart from "./pages/lecture/lecture-start";

class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    const myCookies: any = Cookies.get("user");
    let parsedCookie = {
      name: "",
      token: "",
      powers: [],
    };

    if (typeof myCookies !== "undefined") {
      parsedCookie = JSON.parse(myCookies);
    }

    this.state = {
      name: parsedCookie.name,
      token: parsedCookie.token,
      powers: parsedCookie.powers,
    };
  }
  /*
    IsCookies() {
      const myCookies: any = Cookies.get("user");
  
      if (typeof myCookies !== 'undefined') {
        const parsedCookie = JSON.parse(myCookies);
        console.log(parsedCookie);
        this.setState(parsedCookie);
        return true;
      }
      return false;
    }
  */
  /*function IsCookies() {
  const myCookies: any = Cookies.get("user");

  if (typeof myCookies !== 'undefined') {
    const parsedCookie = JSON.parse(myCookies);
    console.log(parsedCookie);
    setUserCookie(parsedCookie);
  }
  return false;
}
*/
  //if (!IsCookies())
  //return <Login />
  componentDidUpdate(prevprops: any) {
    if (this.state.token !== prevprops.token) console.log("PROPS", prevprops);
  }

  render() {
    const token = this.state.token;
    if (this.state.token)
      return (
        <BrowserRouter basename='/'>
          <Routes>
            <Route path='/' element={<HomeCourses aut={this.state} />} />
            <Route path='/course' element={<CoursePage aut={this.state} />} />
            <Route
              path='/course/:course/lecture-start'
              element={<LectureStart aut={this.state} />}
            />
            <Route
              path='/course/:course/lecture'
              element={<Lecture aut={this.state} />}
            />
            <Route
              path='/course/:course/results/:lesson'
              element={<LectionEnd aut={this.state} />}
            />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      );
    else
      return (
        <BrowserRouter basename='/'>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      );
  }
}

export default App;
