// import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// const loader: any = document.querySelector(".bgload");
// const showLoader = () => loader.classList.remove("loader--hide");
// const hideLoader = () => loader.classList.add("loader--hide");

// showLoader();

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(<App />);
