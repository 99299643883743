//import './lection.css';

import React, { useEffect, useState } from "react";
import MediaFile from "./QuestionComponents/mediaFile";
import QuestionHeader from "./QuestionComponents/question-header";
import TrueOrFalseCheckbox from "./QuestionComponents/trueOrFalseCheckbox";
import { CompleteButton } from "../CompleteButton";
import CurrentTries from "./CurrentTries";

interface Props {
  id: string;
  sentences: any[];
  onRespond: (value: any) => void;
  courseInfo: any;
  step: any;
  current: any;
  currentLessonStep: number;
  currentQuestionStep: number;
  totalQuestions: number;
}

const TrueOrFalse = (props: Props) => {
  const [answers, setAnswers] = useState<any[]>([]);
  const [resetBox, setResetBox] = useState(false);

  function OnValueChange(value: any, index: number) {
    setAnswers((answers) => [
      ...answers.slice(0, index),
      value,
      ...answers.slice(index + 1),
    ]);
  }

  useEffect(() => {
    setResetBox(false);
  }, [resetBox]);

  return (
    <div className='lection'>
      <div className='lection-header'>
        <QuestionHeader
          current={props?.current}
          courseInfo={props?.courseInfo}
          lesson={props?.currentLessonStep}
          question={props?.currentQuestionStep}
          totalQuestionNumber={props?.totalQuestions}
        />
        <MediaFile
          media_type={props?.current?.media_type}
          media_file={props?.current?.media_file}
        />
      </div>
      <div className='lection-body'>
        <div className='lection-container'>
          <div className='lection-TrueOrFalseContainer'>
            <div className='lection-ColumnContainer'>
              {!resetBox
                ? props.sentences.map((sentence: any, index) => (
                    <div key={index} className='custom-indexer'>
                      <div className='circle-number'>{index + 1}</div>
                      <TrueOrFalseCheckbox
                        sentence={sentence}
                        index={index}
                        onValueChanged={(e) => OnValueChange(e, index)}
                      />
                    </div>
                  ))
                : props.sentences.map((sentence: any, index) => (
                    <div key={index} className='sentence'>
                      <div className='lection-sentenceText'>
                        {sentence.phrase}
                      </div>
                      <input
                        type='checkbox'
                        value={sentence.phrase}
                        className='lection-sentenceCheckBox'
                        defaultChecked={false}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className='separator separator-foo' />
      <div className='lection-footer'>
        <div className='flex items-center'>
          {(props?.current?.tries !== undefined ||
            props?.current?.tries !== null) && (
            <CurrentTries tries={props?.current?.tries} className='mr-4' />
          )}
          <CompleteButton
            answer={() => {
              props.onRespond(answers);
              // setResetBox(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TrueOrFalse;
