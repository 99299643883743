import { useEffect, useState } from "react";
import Layout from "../../components/layout";
import CompleteSentenceExercise from "../../components/lection/completeSentence";
import DragNDropCompleteSentence from "../../components/lection/dragNDropCompleteSentence";
import TrueOrFalse from "../../components/lection/trueOrFalse";
//import './lecture.css';
import { useParams } from "react-router";
import DragNDropMultiple from "../../components/lection/dragNDropMultiple";
import DragNDropPhraseOrder from "../../components/lection/dragNDropPhraseOrder";
import LectureHeader from "../../components/lesson-header/lection-header";
import ModalAnswer from "../../components/modals/modalAnswer";
import {
  getTakenCourse,
  nextQuestion,
  resolveQuestion,
} from "../../services/studentCourses";
import { BASE_URL } from "../../services/urlManager";
import { useSearchParams } from "react-router-dom";

const DEFAULT_ROUTE_CHANGE_TIME = 2000;

export const Lecture = (props: any) => {
  let { course } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const lessonId = searchParams.get("lesson") ?? "";
  const stepId = searchParams.get("lessonStep") ?? "";
  const questionId = searchParams.get("question") ?? "";
  const totalQuestions = searchParams.get("totalQuestions") ?? "";

  const [nextStep, setNextStep] = useState<any>(null);
  const [currentQuestion, setCurrentQuestion] = useState<any>(null);

  const [currentComponent, setCurrentComponent] = useState<any>(null);
  const [lectionResponse, setLectionResponse] = useState<any>(null);

  const [showModal, setShowModal] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [modalMode, setModalMode] = useState("");

  const [info, setInfo] = useState<any>(null);

  let btnIsDisabled = false;

  // get taken course
  useEffect(() => {
    getQuestionInfo();
  }, []);

  // next question
  useEffect(() => {
    if (nextStep !== null && typeof nextStep !== "undefined") {
      getNextQuestion();
    }
  }, [nextStep]);

  // cambio de ejercicio
  useEffect(() => {
    if (typeof currentQuestion !== "undefined" && currentQuestion !== null) {
      getExercise(currentQuestion.body.question);
      setTimeout(() => {
        setShowModal(false);
      }, DEFAULT_ROUTE_CHANGE_TIME); //cambiar tiempo de respuesta falsa
    }
  }, [currentQuestion]);

  // respuesta de usuario
  useEffect(() => {
    if (lectionResponse !== null && lectionResponse.response !== null)
      Respond();
  }, [lectionResponse]);

  async function getQuestionInfo() {
    const response: any = await getTakenCourse(props.aut.token, String(course));
    // filter by lesson id
    if (lessonId !== "") {
      const currentLesson = response?.course_data?.lessons?.filter?.(
        (e: any) => e.id === Number(lessonId)
      );
      const currentLessonIndex = response?.course_data?.lessons?.indexOf?.(
        currentLesson?.[0]
      );
      response.course_data.currentLesson = currentLesson?.[0];
      // next lesson
      const nextLessonByIndex =
        response?.course_data?.lessons?.[currentLessonIndex + 1];
      response.course_data.nextLesson = nextLessonByIndex;

      setInfo(response);
    } else {
      setInfo(response);
    }
    // setInfo(response);

    if (response.step !== null) {
      setNextStep(response.step);
    } else {
      window.location.href = `${BASE_URL}/course/${course}/results/${
        response.total_lesson - 1
      }`;
    }
  }

  async function getNextQuestion() {
    const response: any = await nextQuestion(
      props.aut.token,
      String(course),
      stepId !== "" && questionId !== ""
        ? { lesson: Number(stepId), question: Number(questionId) }
        : nextStep
    );
    setCurrentQuestion(response);
  }

  async function resolveQuestionResponse() {
    let responses: any = {};
    if (lectionResponse.phrases) {
      responses = {
        lesson: stepId !== "" ? Number(stepId) : nextStep.lesson,
        question: questionId !== "" ? Number(questionId) : nextStep.question,
        response: lectionResponse.responses,
        phrases: lectionResponse.phrases,
      };
    } else if (lectionResponse.misteries) {
      responses = {
        lesson: stepId !== "" ? Number(stepId) : nextStep.lesson,
        question: questionId !== "" ? Number(questionId) : nextStep.question,
        misteries: lectionResponse.misteries,
        answers: lectionResponse.answers,
      };
    } else {
      responses = {
        lesson: stepId !== "" ? Number(stepId) : nextStep.lesson,
        question: questionId !== "" ? Number(questionId) : nextStep.question,
        response: lectionResponse,
      };
    }

    const response = await resolveQuestion(
      props.aut.token,
      String(course),
      responses
    );

    return response.body.isCorrect;
  }

  function goToNextQuestion() {
    if (currentQuestion?.body?.nextCoord !== null) {
      setTimeout(function () {
        setShowModal(false);
        const nextLessonId =
          info?.course_data?.lessons?.[
            currentQuestion?.body?.nextCoord?.lesson
          ] ?? [];
        const isNextLesson =
          Number(totalQuestions) === Number(info?.step?.question) + 1;

        const currentLesson = isNextLesson
          ? nextLessonId?.id
          : Number(lessonId);

        const currentTotalQuestions = isNextLesson
          ? nextLessonId?.total_questions
          : totalQuestions;

        if (isNextLesson) {
          window.location.href = `${BASE_URL}/course/${course}/lecture-start?lesson=${currentLesson}&lessonStep=${currentQuestion?.body?.nextCoord?.lesson}&question=${currentQuestion?.body?.nextCoord?.question}&totalQuestions=${currentTotalQuestions}`;
        } else {
          window.location.href = `${BASE_URL}/course/${course}/lecture?lesson=${currentLesson}&lessonStep=${currentQuestion?.body?.nextCoord?.lesson}&question=${currentQuestion?.body?.nextCoord?.question}&totalQuestions=${currentTotalQuestions}`;
        }
      }, DEFAULT_ROUTE_CHANGE_TIME);
    } else {
      setTimeout(function () {
        setShowModal(false);
        window.location.href = `${BASE_URL}/course/${course}/results/${nextStep.lesson}`;
      }, DEFAULT_ROUTE_CHANGE_TIME);
    }
  }

  async function Respond() {
    let isResolveCorrect = true;
    if (typeof currentQuestion !== "undefined" && currentQuestion !== null) {
      const resolve: any = await resolveQuestionResponse();
      if (Array.isArray(resolve)) {
        resolve.forEach((e: any) => {
          if (e !== true) isResolveCorrect = false;
        });

        if (isResolveCorrect) {
          setModalMode("correct");
          setShowModal(true);
          goToNextQuestion();
        } else {
          setModalMode("incorrect");
          setShowModal(true);
        }
      } else {
        if (resolve === true) {
          setModalMode("correct");
          setShowModal(true);

          goToNextQuestion();
        } else if (resolve === false) {
          isResolveCorrect = false;
          setModalMode("incorrect");
          setShowModal(true);
        } else if (resolve === "next") {
          setModalMode("next");
          setShowModal(true);
          goToNextQuestion();
        }
      }
      setIsCorrect(isResolveCorrect);

      btnIsDisabled = false;

      if (isResolveCorrect === false) {
        getNextQuestion();
      }
    }
  }
  function getExercise(current: any) {
    switch (current?.script?.kind) {
      case "complete_drag_n_drop":
        setCurrentComponent(
          <DragNDropCompleteSentence
            key={current?.id}
            current={current}
            questions={current?.script?.content?.phrases}
            options={current?.script?.content?.options}
            onRespond={setLectionResponse}
            step={nextStep}
            courseInfo={info}
            currentLessonStep={
              stepId !== "" ? Number(stepId) : nextStep?.lesson
            }
            currentQuestionStep={
              questionId !== "" ? Number(questionId) : nextStep?.question
            }
            totalQuestions={Number(totalQuestions)}
          />
        );
        break;
      case "complete_phrase":
        setCurrentComponent(
          <CompleteSentenceExercise
            key={current?.id}
            current={current}
            sentences={current?.script?.content?.phrases}
            onRespond={setLectionResponse}
            btnDisabled={btnIsDisabled}
            step={nextStep}
            courseInfo={info}
            currentLessonStep={
              stepId !== "" ? Number(stepId) : nextStep?.lesson
            }
            currentQuestionStep={
              questionId !== "" ? Number(questionId) : nextStep?.question
            }
            totalQuestions={Number(totalQuestions)}
          />
        );
        break;
      case "organize_phrase":
        setCurrentComponent(
          <DragNDropPhraseOrder
            key={current?.id}
            current={current}
            phrases={current?.script?.content?.phrases}
            onRespond={setLectionResponse}
            step={nextStep}
            courseInfo={info}
            aut={props?.aut?.token}
            course={course}
            currentLessonStep={
              stepId !== "" ? Number(stepId) : nextStep?.lesson
            }
            currentQuestionStep={
              questionId !== "" ? Number(questionId) : nextStep?.question
            }
            totalQuestions={Number(totalQuestions)}
          />
        );
        break;
      case "asociate_image":
        setCurrentComponent(
          <DragNDropMultiple
            key={current?.id}
            current={current}
            phrases={current?.script?.content?.options}
            droppableContent={current?.script?.content?.image_video}
            onRespond={setLectionResponse}
            course={course}
            aut={props?.aut?.token}
            step={nextStep}
            courseInfo={info}
            type={"images"}
            currentLessonStep={
              stepId !== "" ? Number(stepId) : nextStep?.lesson
            }
            currentQuestionStep={
              questionId !== "" ? Number(questionId) : nextStep?.question
            }
            totalQuestions={Number(totalQuestions)}
          />
        );
        break;
      case "asociate_category":
        setCurrentComponent(
          <DragNDropMultiple
            key={current?.id}
            current={current}
            phrases={current?.script?.content?.sentences}
            droppableContent={current?.script?.content?.categories}
            onRespond={setLectionResponse}
            course={course}
            aut={props?.aut?.token}
            step={nextStep}
            courseInfo={info}
            type={"categories"}
            currentLessonStep={
              stepId !== "" ? Number(stepId) : nextStep?.lesson
            }
            currentQuestionStep={
              questionId !== "" ? Number(questionId) : nextStep?.question
            }
            totalQuestions={Number(totalQuestions)}
          />
        );
        break;
      case "true_or_false":
        setCurrentComponent(
          <TrueOrFalse
            key={current?.id}
            current={current}
            sentences={current?.script?.content?.sentences}
            onRespond={setLectionResponse}
            id={current?.id}
            step={nextStep}
            courseInfo={info}
            currentLessonStep={
              stepId !== "" ? Number(stepId) : nextStep?.lesson
            }
            currentQuestionStep={
              questionId !== "" ? Number(questionId) : nextStep?.question
            }
            totalQuestions={Number(totalQuestions)}
          />
        );
        break;
    }
  }

  return (
    <Layout
      aut={props.aut}
      _classes={"course-layout"}
      imageUrl={
        info !== null && info?.course_data?.media_file !== null
          ? info?.course_data?.media_file
          : ""
      }>
      <div className='wrap conatiner-fluid'>
        {info !== null ? (
          <LectureHeader
            courseInfo={info}
            step={nextStep}
            courseImage={
              info?.course_data?.lessons?.[nextStep?.lesson]?.media_file !==
              null
                ? info?.course_data?.lessons?.[nextStep?.lesson]?.media_file
                : ""
            }
          />
        ) : null}
        {currentComponent}
      </div>
      <ModalAnswer
        tries={(currentQuestion?.body?.question?.tries ?? 0) + 0}
        modalMode={modalMode}
        showModal={showModal}
        onModalClose={() => setShowModal(false)}
      />
    </Layout>
  );
};
