import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Layout from "../../components/layout";
import { getTakenCourse, likeOrDislike } from "../../services/studentCourses";
import { API_BASE_URL, API_URL, BASE_URL } from "../../services/urlManager";
//import './lecture-end.css';

const LectionEnd = (props: any) => {
  //const { handleLogin, token, hostname, isLoged } = useContext(AdminContext);
  let { course, lesson }: any = useParams();

  const [loading, setLoading] = useState(true);
  const [courseInfo, setCourseInfo] = useState<any>();
  const [titleText, setTitleText] = useState<string>(
    "¡Felicitaciones, has completado la lección!"
  );
  const [encourageText, setEncourageText] = useState("¡Muy bien, sigue así!");
  const [like, setLike] = useState<any>(null);

  let perc = courseInfo?.percent_complete;
  let total = courseInfo?.total_questions;
  let questionArray: any = new Array(total);

  let current;
  if (perc > 0) current = (total / perc) * 100;
  else current = 0;

  for (let i: number = 0; i < questionArray.length; i++) {
    if (i >= current) {
      questionArray[i] = false;
    } else {
      questionArray[i] = true;
    }
  }

  async function getQuestionStep() {
    const response: any = await getTakenCourse(
      props?.aut?.token,
      String(course)
    );
    setCourseInfo(response);
    setTexts(response?.percent_complete);
    setLoading(false);
  }

  function setTexts(value: any) {
    if (value >= 25 && value < 50) {
      setTitleText("¡Sigue aprendiendo, has completado el curso!");
      setEncourageText("Puedes mejorar, ¡sigue aprendiendo!");
    } else if (value >= 50 && value < 75) {
      setTitleText("¡Buen trabajo, completaste el curso!");
      setEncourageText("¡Buen trabajo, completaste el curso!");
    } else if (value >= 75 && value < 100) {
      setTitleText("¡Bien hecho, has completado el curso!");
      setEncourageText("¡Muy bien, sigue así!");
    } else if (value === 100) {
      setTitleText("Excelente, sigue así!");
      setEncourageText("¡Felicitaciones, has completado el curso!");
    } else {
      setTitleText("¡Felicitaciones, has completado el curso!");
      setEncourageText("¡Muy bien, sigue así!");
    }
  }

  async function setLikeOrDislike(value: boolean) {
    const response = await likeOrDislike(props?.aut?.token, String(course), {
      lesson: courseInfo?.course_data?.lessons?.[lesson]?.id,
      like: value,
    });
    console.log(response);
  }

  useEffect(() => {
    if (like !== null) setLikeOrDislike(like);
  }, [like]);

  useEffect(() => {
    getQuestionStep();
  }, []);

  return (
    <Layout
      aut={props.aut}
      _classes='course-layout'
      imageUrl={
        typeof courseInfo !== "undefined" &&
        courseInfo?.course_data?.media_file !== null
          ? courseInfo?.course_data?.media_file
          : ""
      }>
      {loading ? null : (
        <div className='wrap container-fluid'>
          <div className='lecture-end'>
            <div className='lecture-endHeader'>
              <div className='lecture-endHeader-container'>
                <div className='lecture-avatarHeader'>
                  <div className='lecture-avatar'>
                    <div className='avatarImage'>
                      {courseInfo?.course_data?.lessons?.[lesson]
                        ?.media_file !== null ? (
                        <img
                          src={
                            API_URL +
                            "" +
                            courseInfo?.course_data.lessons?.[lesson]
                              ?.media_file
                          }
                        />
                      ) : (
                        <img
                          src={require("../../Assets/images/curso-small.jpg")}
                        />
                      )}
                    </div>
                    <div className='avatarTextContainer'>
                      <div className='avatar-title'>
                        {courseInfo?.course_data?.name}
                      </div>
                      <div className='avatar-subtitle'>
                        {courseInfo?.course_data?.level_class_name}
                      </div>
                    </div>
                  </div>
                  <div className='lecture-questionTitle'></div>
                </div>
                <div className='separator' />
              </div>
            </div>
            <div className='lecture-endBody'>
              <div className='lecture-endBodyContainer'>
                <div className='lecture-endBody-title'>{titleText}</div>
                <div className='lecture-endBody-prctContainer'>
                  <div className='lecture-endBody-prctTextContainer'>
                    <div className='lecture-endBody-prctText'>
                      {Math.round(Number(courseInfo?.percent_complete))}%
                    </div>
                    <div className='separator' />
                    <div className='lecture-endBody-subtitle'>
                      Tu porcentaje de progreso
                    </div>
                  </div>
                </div>
                <div className='lecture-endBody-encourageText'>
                  {encourageText}
                </div>
                <div className='separator' />
                <div className='lecture-likeContainer'>
                  <div className='lecture-endBody-likeText'>
                    ¿Te gustó el curso?
                  </div>
                  <div className='like-iconsContainer'>
                    <div
                      className={
                        like === null || !like ? "likeBtn" : "likeBtn-disabled"
                      }
                      onClick={() => {
                        if (like === null || like) setLike(false);
                      }}>
                      <img
                        className='lecture-endBody-likeIcon'
                        src={require("../../Assets/icons/no-like3.png")}
                      />
                    </div>
                    <div
                      className={
                        like === null || like ? "likeBtn" : "likeBtn-disabled"
                      }
                      onClick={() => {
                        if (like === null || !like) setLike(true);
                      }}>
                      <img
                        className='lecture-endBody-likeIcon'
                        src={require("../../Assets/icons/like.png")}
                      />
                    </div>
                  </div>
                </div>
                <div className='separator' />
                <div className='lection-footer'>
                  <a href={BASE_URL + "/"} className='back' title=''>
                    Ir al Inicio
                  </a>
                  <a
                    className='return-btn'
                    href={BASE_URL + "/course?course=" + course}>
                    Volver al curso
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default LectionEnd;
